import React, { useState } from "react";
import WelcomeName from "../components/WelcomeName";
import { useMsal } from "@azure/msal-react";
import {
  InteractionStatus,
  InteractionRequiredAuthError,
} from "@azure/msal-browser";
import { loginRequest } from "../services/authProvider";
import { callMsGraph } from "../services/msGraphApiCall";
import { callUsers } from "../services/msGraphApiCall";
import "./css/LicenseManagement.css";
import "../App.css";


const LicenseManagementUser = () => {
  const { instance, inProgress } = useMsal();
  const [graphData, setGraphData] = useState(null);
  const [users, setUsers] = useState(null);

  // const setProfilePicture = (user) => {
  //   if (user) {
  //     var name = user.givenName;
  //     var lastname = user.surname;
  //     var initials = "";
  //     if (name) {
  //       initials = name.charAt(0);
  //     }
  //     if (lastname) {
  //       initials = initials + lastname.charAt(0);
  //     }
  //     return initials;
  //   }
  // };

  // const search = (event) => {
  //   const { value } = event.target;
  //   callUsers(null, value)
  //     .then((response) => {
  //       const list = response.value.filter(
  //         (user) => user.userPrincipalName !== null
  //       );
  //       setUsers(list);
  //     })
  //     .catch((e) => {
  //       if (e instanceof InteractionRequiredAuthError) {
  //         instance.acquireTokenRedirect({
  //           ...loginRequest,
  //           account: instance.getActiveAccount(),
  //         });
  //       }
  //     });
  // };

  // const assignAdmin = async (id) => {
  //   console.log('admin')
  //   await getAndRemoveRole(id)
  //     addAppRoleAdmin(null, id)
  //     .then(() => {
  //       getUserAppRoles(null,id)
  //       .then((response1) => {
  //         console.log('after delete and adding',response1);
  //       })
  //     })
  // }

  // const assignUser = async (id) => {
  //   console.log('user')
  //   await getAndRemoveRole(id)
  //   addAppRoleUser(null, id)
  //   .then(() => {
  //     getUserAppRoles(null,id)
  //     .then((response1) => {
  //       console.log('after delete and adding',response1);
  //     })
  //   })
  // }

  // const getAndRemoveRole = async (id) => {
  //   const response = await getUserAppRoles(null,id)
  //   console.log(response)
  //     const resourceid = response.value;
  //     for(let x = 0; x< resourceid.length ; x++)
  //     {
  //       if(resourceid[x].resourceDisplayName === 'vodium-landing-page-dev' && resourceid[x].appRoleId !== '00000000-0000-0000-0000-000000000000') {
  //         await removeRole(resourceid[x].id)
  //       }
  //     }
  // }

  // const removeRole = async (id) => {
  //   await removeAppRole(null, id)
  // }

  // const getRoleId = (id) => {
  //   return "role-" + id;
  // }

  // const assignRole = (id) => {
  //   const roleSelect = document.getElementById(getRoleId(id)).value;
  //   if (roleSelect === 'Admin') {
  //     assignAdmin(id)
  //   }
  //   else if (roleSelect === 'User') {
  //     assignUser(id)
  //   }
  // }

  React.useEffect(() => {
    if (!graphData && inProgress === InteractionStatus.None) {
      callMsGraph()
        .then((response) => setGraphData(response))
        .catch((e) => {
          if (e instanceof InteractionRequiredAuthError) {
            instance.acquireTokenRedirect({
              ...loginRequest,
              account: instance.getActiveAccount(),
            });
          }
        });
    }

    if (!users && inProgress === InteractionStatus.None) {
      callUsers()
        .then((response) => {
          setUsers(response.value);
        })
        .catch((e) => {
          if (e instanceof InteractionRequiredAuthError) {
            instance.acquireTokenRedirect({
              ...loginRequest,
              account: instance.getActiveAccount(),
            });
          }
        });
    }

    console.log(graphData);
  }, [inProgress, graphData, instance]);

  return (
    <div className="flex flex-col flex-1 overflow-x-hidden license-management-page">
      <main>
        <div className="pt-10 pb-10 md:pt-16 xl:pt-28">
          <div className="px-6 mx-auto">
            <div className="md:items-center md:flex">
              <WelcomeName viewpage={"HOME"} />
              <p className="mt-1 text-base font-regular text-gray-500 md:mt-0 md:ml-2">
                It seems like you don't have permission to view this page.
              </p>
            </div>
          </div>
        </div>

        {/* <div className="px-6 mx-auto">
          <div className="space-y-5 sm:space-y-6">
            <div className="grid gap-5">
              <div className="bg-white border border-gray-200 rounded">
                <div className="px-5 py-4">
                  <p className="text-xs font-medium tracking-wider text-gray-500 uppercase">
                    LICENSES
                  </p>
                </div>
                <div className="row">
                  <div className="px-6  row">
                    <div className="space-y-5 sm:space-y-6 column count-container">
                      <div className="grid gap-5">
                        <div className="border border-gray-200 rounded">
                          <div className="px-5 py-4">
                            <div className="items-center justify-between">
                              License count: 0
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="space-y-5 sm:space-y-6 column count-container">
                      <div className="grid gap-5">
                        <div className="border border-gray-200 rounded">
                          <div className="px-5 py-4">
                            <div className="items-center justify-between">
                              Available to assign: 0
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="space-y-5 sm:space-y-6 column">
                    <input
                      className="search-field"
                      placeholder="Search by name or email"
                      onChange={search}
                    ></input>
                  </div>
                </div>
                <div>
                  <table className="table-list">
                    <th className="user-column">Users</th>
                    <th className="role-column">Role</th>
                    <th className="message-column">Notify By</th>
                    <th className="license-column">License</th>
                    <tbody>
                      {users &&
                        users.map((item, index) => (
                          <tr key={item.id}>
                            <td>
                              <div className="row">
                                <div className="column pic-container">
                                  <span className="pic-initials">
                                    {setProfilePicture(item)}
                                  </span>
                                </div>
                                <div className="column profile-list-details">
                                  <div>{item.displayName}</div>
                                  <div>{item.userPrincipalName}</div>
                                </div>
                              </div>
                            </td>
                            <td className="center">
                              <select
                                defaultValue={"User"}
                                id={getRoleId(item.id)}
                                onChange={() => assignRole(item.id)}
                              >
                                <option disabled={true}>Select</option>
                                <option value="User">User</option>
                                <option value="Admin">Admin</option>
                              </select>
                            </td>
                            <td>
                              <select defaultValue={"email"}>
                                <option disabled={true}>Select</option>
                                <option id="email" value="email">via email</option>
                                <option id="teamsChat" value="teamsChat">via Teams chat</option>
                                <option id="teamsChatEmail" value="teamsChatEmail">via Teams chat & email</option>
                              </select>
                            </td>
                            <td className="center">
                              <label className="switch">
                                <input type="checkbox" />
                                <span className="slider round"></span>
                              </label>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                    {!users && (
                      <tbody>
                        <tr>
                          <td>No user available</td>
                        </tr>
                      </tbody>
                    )}
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </main>
    </div>
  );
};

export default LicenseManagementUser;
