import React, { useState } from "react";
import "../../App.css";
import { ACTION_HANDLER_TYPE } from "../../shared/common/constant";
import ActionHandler from "../../services/data-handler";

const ContactSupportForm = (props) => {
  const [inputValues, setInputValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    company: "",
    jobTitle: "",
    vodiumUse: "",
    message: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (
      [
        "firstName",
        "lastName",
        "email",
        "company",
        "jobTitle",
        "message",
      ].includes(name)
    ) {
      if (value.trim() === "") {
        displayError(
          name + "Error",
          formatCamelCase(name) + " cannot be empty."
        );
      } else {
        if (name === "email" && !isValidEmail(value.trim())) {
          displayError("emailError", "Invalid email format.");
        } else {
          clearError(name);
        }
      }
    }
    setInputValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  function formatCamelCase(inputString) {
    // Add space before each capital letter and capitalize the first letter
    var string = inputString.replace(/([A-Z])/g, " $1").trim();
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }

  const isValidEmail = (email) => {
    // Regular expression for basic email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const clearError = (elementId) => {
    document.getElementById(elementId).style.border = "1px solid #6b7280";
    document.getElementById(elementId + "Error").style.display = "none";
  };

  const displayError = (elementId, message) => {
    document.getElementById(elementId).innerText = message;
    document.getElementById(elementId).style.display = "block";
    document.getElementById(elementId).previousSibling.style.border =
      "1px solid #dd59c3";
  };

  const validateForm = () => {
    const firstName = document.getElementById("firstName").value;
    const lastName = document.getElementById("lastName").value;
    const email = document.getElementById("email").value;
    const company = document.getElementById("company").value;
    const jobTitle = document.getElementById("jobTitle").value;
    const message = document.getElementById("message").value;

    var isValid = true;
    if (firstName.trim() === "") {
      displayError("firstNameError", "First name cannot be empty.");
      isValid = false;
    }
    if (lastName.trim() === "") {
      displayError("lastNameError", "Last name cannot be empty.");
      isValid = false;
    }
    if (email.trim() === "") {
      displayError("emailError", "Email cannot be empty.");
      isValid = false;
    } else {
      if (!isValidEmail(email.trim())) {
        displayError("emailError", "Invalid email format.");
        isValid = false;
      }
    }
    if (company.trim() === "") {
      displayError("companyError", "Company cannot be empty.");
      isValid = false;
    }
    if (jobTitle.trim() === "") {
      displayError("jobTitleError", "Job title cannot be empty.");
      isValid = false;
    }
    if (message.trim() === "") {
      displayError("messageError", "Message cannot be empty.");
      isValid = false;
    }
    return isValid;
  };

  const submit = () => {
    if (validateForm()) {
      document.getElementById("loaderOverlay").style.display = "flex";
      ActionHandler(ACTION_HANDLER_TYPE.SEND_EMAIL_CONTACT, inputValues).then(
        () => {
          document.getElementById("loaderOverlay").style.display = "none";
          props.toggleView({ id: "EMAIL_SENT", display: "" });
        }
      );
    }
  };

  React.useEffect(() => {
    // eslint-disable-next-line
  }, []);
  return (
    <div className="loader-container">
      <div
        className="grid grid-cols-1 md:grid-cols-2 loader-overlay"
        id="loaderOverlay"
      >
        <div className="loader"></div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2">
        <div className="form-div column">
          <label
            htmlFor="firstName"
            className="text-xs font-medium tracking-wider text-gray-500 uppercase"
          >
            First Name *
          </label>
          <input
            className="form-input"
            type="text"
            id="firstName"
            name="firstName"
            placeholder="Your first name.."
            value={inputValues.firstName}
            onChange={handleInputChange}
          />
          <div id="firstNameError" className="error-message"></div>
        </div>
        <div className="form-div column">
          <label
            htmlFor="lastName"
            className="text-xs font-medium tracking-wider text-gray-500 uppercase"
          >
            Last Name *
          </label>
          <input
            className="form-input"
            type="text"
            id="lastName"
            name="lastName"
            placeholder="Your last name.."
            value={inputValues.lastName}
            onChange={handleInputChange}
          />
          <div id="lastNameError" className="error-message"></div>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2">
        <div className="form-div column">
          <label
            htmlFor="email"
            className="text-xs font-medium tracking-wider text-gray-500 uppercase"
          >
            Email *
          </label>
          <input
            className="form-input"
            type="text"
            id="email"
            name="email"
            placeholder="Your email.."
            value={inputValues.email}
            onChange={handleInputChange}
          />
          <div id="emailError" className="error-message"></div>
        </div>
        <div className="form-div column">
          <label
            htmlFor="phoneNumber"
            className="text-xs font-medium tracking-wider text-gray-500 uppercase"
          >
            Phone Number
          </label>
          <input
            className="form-input"
            type="text"
            id="phoneNumber"
            name="phoneNumber"
            placeholder="Your phone number.."
            value={inputValues.phoneNumber}
            onChange={handleInputChange}
          />
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2">
        <div className="form-div column">
          <label
            htmlFor="company"
            className="text-xs font-medium tracking-wider text-gray-500 uppercase"
          >
            Company Name *
          </label>
          <input
            className="form-input"
            type="text"
            id="company"
            name="company"
            placeholder="Your company name.."
            value={inputValues.company}
            onChange={handleInputChange}
          />
          <div id="companyError" className="error-message"></div>
        </div>
        <div className="form-div column">
          <label
            htmlFor="jobTitle"
            className="text-xs font-medium tracking-wider text-gray-500 uppercase"
          >
            Job Title *
          </label>
          <input
            className="form-input"
            type="text"
            id="jobTitle"
            name="jobTitle"
            placeholder="Your job title.."
            value={inputValues.jobTitle}
            onChange={handleInputChange}
          />
          <div id="jobTitleError" className="error-message"></div>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2">
        <div className="form-div column">
          <label
            htmlFor="vodiumUse"
            className="text-xs font-medium tracking-wider text-gray-500 uppercase"
          >
            How do you or your team plan to use VODIUM?
          </label>
          <select
            className="form-input"
            id="vodiumUse"
            name="vodiumUse"
            value={inputValues.vodiumUse}
            onChange={handleInputChange}
          >
            <option value="" disabled={true}>
              Please Select
            </option>
            <option value="Conferences">Conferences</option>
            <option value="HR">HR</option>
            <option value="Interviewing">Interviewing</option>
            <option value="Meetings">Meetings</option>
            <option value="Presentations">Presentations</option>
            <option value="Recording">Recording</option>
            <option value="Other">Other</option>
          </select>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2">
        <div className="col-span-2">
          <div className="form-div column textarea-div">
            <label
              htmlFor="message"
              className="text-xs font-medium tracking-wider text-gray-500 uppercase"
            >
              Message *
            </label>
            <textarea
              className="form-input"
              id="message"
              name="message"
              placeholder="Your message.."
              value={inputValues.message}
              onChange={handleInputChange}
            />
            <div id="messageError" className="error-message"></div>
          </div>
        </div>
      </div>
      <button
        className="blue button submit-button"
        type="submit"
        onClick={submit}
      >
        Submit
      </button>
    </div>
  );
};
export default ContactSupportForm;
