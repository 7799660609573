import React from "react";
import { validateFieldByType } from "./Utils";
import { ActionTypes, FORM } from "./common/constant";
import { classNames } from "./Utils";
import Datepicker from "flowbite-datepicker/Datepicker";
import { connect } from "react-redux";
import { changePlan } from "../services/subscriptions";

const Form = ({ form, className, onAction, isBtnLoading, accessToken }) => {
  const [isFormSubmitted, setIsFormSubmitted] = React.useState(false);
  const [formErrors, setFormErrors] = React.useState({});
  const [inputChipValues, setInputChipValues] = React.useState({});
  const [formKeys, setFormKeys] = React.useState([]);
  const isMounted = React.useRef(true);

  React.useEffect(() => {
    const datePickers = form.fields.filter(
      (field) => field.element === FORM.ELEMENTS.DATE_PICKER
    );
    if (datePickers.length) {
      datePickers.forEach((datePicker) => {
        const datepickerEl = document.getElementById(datePicker.name);
        new Datepicker(datepickerEl, datePicker.options);
      });
    }
    // eslint-disable-next-line
  }, []);

  React.useMemo(() => {
    if (isMounted.current) {
      const errorObject = {};
      const inputChipObject = {};
      const formKeysArray = [];
      form.fields.forEach((field) => {
        errorObject[field.name] = [];
        formKeysArray.push(field.name);
        if (field.element === FORM.ELEMENTS.INPUT_CHIP) {
          inputChipObject[field.name] = [];
        }
      });
      setFormKeys(formKeysArray);
      setFormErrors(errorObject);
      setInputChipValues(inputChipObject);
    }
    return () => (isMounted.current = false);
  }, [form]);

  const handleValidation = ({ field, value }) => {
    if (!field.validation) {
      return [];
    }
    if (field.validation.min && field.validation.max) {
      if (value < field.validation.min || value > field.validation.max) {
        return [
          `Value should be between ${field.validation.min} and ${field.validation.max}`,
        ];
      }
    }
    return [];
  };

  const formSubmit = async (event) => {
    event.preventDefault();
    const submittedForm = event.target;
    const errors = { ...formErrors };
    const values = {};
    formKeys.forEach((key) => {
      let value = undefined;
      const formField = form.fields.find((field) => field.name === key);
      // Handle field value by type
      switch (submittedForm[key].type) {
        case FORM.TYPES.CHECKBOX:
          value = submittedForm[key].checked;
          break;
        default:
          if (formField.element === FORM.ELEMENTS.INPUT_CHIP) {
            value = inputChipValues[key];
          } else {
            if (formField.type === FORM.TYPES.NUMBER) {
              value = Number(submittedForm[key].value);
            } else {
              value = submittedForm[key].value;
            }
          }
          break;
      }
      values[key] = value;
      if (submittedForm[key].required || formField.required) {
        errors[key] = validateFieldByType({
          type: submittedForm[key].type,
          value,
          element: formField.element,
        });
        if (!errors[key].length) {
          errors[key] = handleValidation({ field: formField, value });
        }
      }
    });
    const formType = submittedForm.id.split("_")[0];
    const subscriptionId = submittedForm.id.split("_")[1];
    console.log(formType, subscriptionId);
    if (formType === "changePlanForm" && subscriptionId) {
      await changePlan(values.planId, subscriptionId);
    }
    setFormErrors(errors);
    setIsFormSubmitted(true);
    if (!Object.keys(errors).find((key) => errors[key].length > 0)) {
      onAction({ type: ActionTypes.formSubmit, event, data: values, form });
    }
  };

  const validateOnChange = (event, field = null) => {
    if (isFormSubmitted) {
      if (event.target.required) {
        const errors = validateFieldByType({
          type: event.target.type,
          element: field?.element,
          value:
            event.target.type === FORM.TYPES.CHECKBOX
              ? event.target.checked
              : event.target.value,
        });
        setFormErrors({
          ...formErrors,
          [event.target.name]: errors,
        });
      } else if (field && field.required === true) {
        // Handle custom required validation here...
        if (formErrors[field.name].length !== 0) {
          setFormErrors({
            ...formErrors,
            [field.name]: [],
          });
        }
      }
    }
  };

  const addChipValue = (fieldSchema) => {
    const field = document.getElementById(form.id)[fieldSchema.name];
    const errors = validateFieldByType({
      type: field.type,
      value: field.value,
    });
    if (!errors.length) {
      const fieldChipValues = inputChipValues[fieldSchema.name];
      fieldChipValues.push(field.value);
      setInputChipValues({
        ...inputChipValues,
        [fieldSchema.name]: fieldChipValues,
      });
      field.value = "";
    }
    setFormErrors({
      ...formErrors,
      [fieldSchema.name]: errors,
    });
  };

  const removeChipValue = (fieldSchema, index) => {
    const fieldChipValues = inputChipValues[fieldSchema.name];
    if (fieldChipValues && fieldChipValues.length > index) {
      fieldChipValues.splice(index, 1);
      setInputChipValues({
        ...inputChipValues,
        [fieldSchema.name]: fieldChipValues,
      });
    }
  };

  const getLabelByElement = (field) => {
    if (field.element === FORM.ELEMENTS.INPUT_CHECKBOX) {
      return null;
    } else if (field.isInlineLabel) {
      return (
        <div className="flex items-center justify-between">
          <label htmlFor="" className="text-sm font-bold text-gray-900">
            {" "}
            {field.label} {field.required ? " *" : null}{" "}
          </label>
          <p
            onClick={(event) =>
              onAction({ type: field.onActionType, event, form })
            }
            className="text-sm font-medium text-indigo-600 hover:text-indigo-700 cursor-pointer"
          >
            {field.anchor}
          </p>
        </div>
      );
    } else {
      return (
        <label htmlFor="" className="text-sm font-bold text-gray-900">
          {" "}
          {field.label} {field.required ? " *" : null}{" "}
        </label>
      );
    }
  };

  const getFieldByElement = (field) => {
    switch (field.element) {
      case FORM.ELEMENTS.INPUT:
        return (
          <input
            required={field.required}
            type={field.type}
            name={field.name}
            placeholder={field.placeholder}
            defaultValue={field.defaultValue}
            onChange={validateOnChange}
            className={classNames(
              "block w-full px-4 py-3 placeholder-gray-500 border -gray-300 rounded focus:ring-indigo-600 focus:border-indigo-600 sm:text-sm caret-indigo-600",
              field.className
            )}
          />
        );
      case FORM.ELEMENTS.SELECT:
        return (
          <select
            name={field.name}
            placeholder={field.placeholder}
            defaultValue={field.defaultValue ? field.defaultValue : 0}
            required={field.required}
            className={classNames(
              "block w-full py-3 pl-4 pr-10 border border-gray-300 rounded focus:outline-none focus:ring-indigo-600 focus:border-indigo-600 sm:text-sm",
              field.className
            )}
            onChange={validateOnChange}
          >
            <option hidden={true} value={0}>
              {field.placeholder}
            </option>
            {field.options.map((option, optionIndex) => (
              <option key={optionIndex} value={option.id}>
                {option.name}
              </option>
            ))}
          </select>
        );
      case FORM.ELEMENTS.INPUT_CHECKBOX:
        return (
          <div className="relative flex items-center">
            <div className="flex items-center h-5">
              <input
                required={field.required}
                type={field.type}
                name={field.name}
                id={field.name}
                defaultValue={field.defaultValue}
                onChange={validateOnChange}
                className={classNames(
                  "w-4 h-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-600",
                  field.className
                )}
              />
            </div>

            <div className="ml-3">
              <label
                htmlFor={field.name}
                className="text-sm font-medium text-gray-900"
              >
                {" "}
                {field.label}{" "}
              </label>
            </div>
          </div>
        );
      case FORM.ELEMENTS.INPUT_CHIP:
        return (
          <div className="items-center">
            <div className="flex flex-col items-center mt-1 text-sm sm:flex-row sm:justify-between">
              <div className="w-full sm:w-4/5 mb-2 sm:mb-0">
                <input
                  required={false}
                  type={field.type}
                  name={field.name}
                  placeholder={field.placeholder}
                  defaultValue={field.defaultValue}
                  onChange={(e) => validateOnChange(e, field)}
                  className={classNames(
                    "block w-full px-4 py-3 placeholder-gray-500 border -gray-300 rounded focus:ring-indigo-600 focus:border-indigo-600 sm:text-sm caret-indigo-600",
                    field.className
                  )}
                />
              </div>
              <div
                onClick={() => addChipValue(field)}
                className="w-full sm:w-1/6 inline-flex items-center cursor-pointer justify-center py-4 px-3 text-sm font-semibold leading-5 text-white transition-all duration-200 bg-indigo-600 border border-transparent rounded focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600 hover:bg-indigo-500"
              >
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7 1V13M13 7L1 7"
                    stroke="#F9FAFB"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </div>
            {inputChipValues[field.name] &&
              inputChipValues[field.name].length !== 0 && (
                <div className="px-2 py-1 my-2 flex flex-wrap">
                  {inputChipValues[field.name].map((value, index) => {
                    return (
                      <span
                        key={index}
                        className="flex flex-wrap pl-4 pr-2 py-1 m-1 justify-between items-center text-xs font-medium font-semibold rounded cursor-pointer bg-gray-200 text-black-100"
                      >
                        {value}
                        <svg
                          onClick={() => removeChipValue(field, index)}
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-5 w-5 ml-3 hover:text-gray-600"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </span>
                    );
                  })}
                </div>
              )}
          </div>
        );
      case FORM.ELEMENTS.DATE_PICKER:
        return (
          <div className="relative">
            <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
              <svg
                aria-hidden="true"
                className="w-5 h-5 text-gray-500"
                fillRule="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </div>
            <input
              required={field.required}
              name={field.name}
              id={field.name}
              type="text"
              className="border border-gray-300 text-indigo-600 sm:text-sm rounded focus:ring-indigo-600 block w-full pl-10 p-2.5"
              placeholder="Select expiry date"
              onChange={(e) => validateOnChange(e, field)}
            />
          </div>
        );
      case FORM.ELEMENTS.DROPDOWN:
        return (
          <select
            required={field.required}
            type={field.type}
            name={field.name}
            placeholder={field.placeholder}
            defaultValue={field.defaultValue}
            onChange={validateOnChange}
            className={classNames(
              "block w-full px-4 py-3 placeholder-gray-500 border -gray-300 rounded focus:ring-indigo-600 focus:border-indigo-600 sm:text-sm caret-indigo-600",
              field.className
            )}
          >
            {field.options ? (
              field.options.map((option, i) => {
                return (
                  <option
                    key={i}
                    value={option.value}
                    selected={field.selectedOption === option.value}
                  >
                    {option.displayName}
                  </option>
                );
              })
            ) : (
              <>
                <option value="false">Bronze</option>
                <option value="true">Silver</option>
                <option value="true">Gold</option>
              </>
            )}
          </select>
        );
      default:
        return null;
    }
  };

  return (
    <form
      id={form.subscriptionId ? `${form.id}_${form.subscriptionId}` : form.id}
      onSubmit={formSubmit}
      className={classNames(className)}
    >
      <div className="space-y-4">
        {form.fields &&
          form.fields.map((field, fieldIndex) => (
            <div key={fieldIndex}>
              {getLabelByElement(field)}

              <div className="mt-2">{getFieldByElement(field)}</div>
              {formErrors[field.name] && (
                <ul className="pl-4 mt-2 text-sm font-medium text-red-500 list-disc list-outside">
                  {formErrors[field.name].map((err, i) => (
                    <li key={i}>{err}</li>
                  ))}
                </ul>
              )}
            </div>
          ))}
      </div>

      {form.buttons && (
        <div className="flex items-center justify-end mt-5 space-x-4">
          {form.buttons.map((btn, btnIndex) => (
            <button
              key={btnIndex}
              onClick={(event) =>
                onAction({ type: btn.onActionType, event, form })
              }
              type={btn.type}
              className={classNames(btn.className)}
            >
              <svg
                aria-hidden="true"
                className={`${
                  btn.type === FORM.TYPES.SUBMIT && isBtnLoading === true
                    ? ""
                    : "hidden"
                } mr-2 w-5 h-5 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600`}
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
              {btn.label}
            </button>
          ))}
        </div>
      )}
    </form>
  );
};

const mapStateToProps = (state) => ({
  isBtnLoading: state.app.isBtnLoading,
  accessToken: state.app.accessToken,
});

export default connect(mapStateToProps)(Form);
