import React, { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { InteractionStatus, InteractionRequiredAuthError } from "@azure/msal-browser";
import { callMsGraph } from "../services/msGraphApiCall";
import { loginRequest } from "../services/authProvider";
import { login } from "../services/auth.service";
import { useLocation, useNavigate } from "react-router-dom";
import { activateSubscription, resolveSubscription } from "../services/subscriptions";
import { connect } from "react-redux";

const Confirmation = () => {
  const { instance, inProgress } = useMsal();
  const [graphData, setGraphData] = useState(null);
  const [subscriptionData, setSubscriptionData] = useState({
    subscriptionId: "",
    offerId: "",
    planId: "",
    subscriptionName: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [userData, setUserData] = useState({
    userName: "",
    email: "",
  });
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  const navigateToActivate = () => {
    const activateSub = async () => {
      await activateSubscription(
        subscriptionData.subscriptionId,
        subscriptionData.planId
      );
      navigate("/activate");
    };
    activateSub();
  };

  const location = useLocation();
  const queryParamToken = new URLSearchParams(location.search);
  const customerIdentificationToken = queryParamToken.get("token");
  const decodedMarketplaceToken = decodeURIComponent(customerIdentificationToken);

  useEffect(() => {
    const fetchResolveSubscription = async () => {
      setIsLoading(true);
      try {
        console.log("Fetching subscription with token:", decodedMarketplaceToken);
        const response = await resolveSubscription(decodedMarketplaceToken);
        
        console.log("Resolve Subscription Response:", response);

        if (response && response.subscription) {
          setSubscriptionData({
            subscriptionId: response.subscription.id || "",
            offerId: response.offerId || "",
            planId: response.planId || "",
            subscriptionName: response.subscriptionName || "",
          });
        } else {
          console.error("Invalid subscription response:", response);
          setErrorMessage("Failed to retrieve subscription details.");
        }
      } catch (error) {
        console.error("Error resolving subscription:", error);
        setErrorMessage("Failed to resolve subscription. Please try again.");
      } finally {
        setIsLoading(false);
      }
    };

    if (decodedMarketplaceToken) {
      fetchResolveSubscription();
    }
  }, [decodedMarketplaceToken]);

  useEffect(() => {
    const checkActiveAccount = async () => {
      const account = instance.getActiveAccount();
      if (!account) {
        setErrorMessage("No active account! Please sign in to continue.");
        login();
      } else {
        setErrorMessage("");
        setUserData({
          userName: account.name || "Unknown User",
          email: account.username || "Unknown Email",
        });
      }
    };

    if (inProgress === InteractionStatus.None) {
      checkActiveAccount();
    }
  }, [instance, inProgress]);

  useEffect(() => {
    const fetchGraphData = async () => {
      const account = instance.getActiveAccount();

      if (!account) return;

      if (!graphData && inProgress === InteractionStatus.None) {
        try {
          const response = await callMsGraph();
          setGraphData(response);
        } catch (e) {
          if (e instanceof InteractionRequiredAuthError) {
            instance.acquireTokenRedirect({
              ...loginRequest,
              account,
            });
          } else {
            console.error("Error fetching graph data:", e);
          }
        }
      }
    };

    fetchGraphData();
  }, [graphData, instance, inProgress]);

  return (
    <div className="flex flex-col flex-1 overflow-x-hidden">
      <main>
        <div className="pt-24 pb-10 xl:pt-28">
          <div className="px-6 mx-auto">
            <div className="md:items-center md:flex">
              <p className="main-content-label">Subscriptions</p>
            </div>
          </div>
        </div>

        <div className="px-6 mx-auto">
          {errorMessage && (
            <div className="bg-red-100 text-red-700 border border-red-400 px-4 py-3 rounded mb-4">
              {errorMessage}
            </div>
          )}
          <div className="space-y-5 sm:space-y-6">
            <div className="grid gap-5">
              <div className="bg-white border border-gray-200 rounded min-h-64">
                <div className="px-5 py-4 h-full">
                  {!customerIdentificationToken && (
                    <div className="flex items-center justify-center">
                      You currently don't have a subscription. Purchase an offer here.
                    </div>
                  )}
                  {isLoading && (
                    <div className="h-full flex justify-center items-center">
                      <div className="loader divCenter"></div>
                    </div>
                  )}
                  {!isLoading && customerIdentificationToken && (
                    <>
                      <p className="text-xs font-medium tracking-wider text-gray-500 uppercase">
                        To configure your Vodium subscription, please click “Activate Subscription” below.
                        This is the final step to turn on your subscription for VODIUM for Microsoft Teams.
                      </p>
                      <div className="mt-3">
                        <p>User: {userData.userName}</p>
                        <p>Email: {userData.email}</p>
                        <p>Offer ID: {subscriptionData.offerId}</p>
                        <p>Plan ID: {subscriptionData.planId}</p>
                        <p>Subscription Name: {subscriptionData.subscriptionName}</p>
                      </div>

                      <button
                        className="blue button view-button mr-3"
                        onClick={navigateToActivate}
                      >
                        Activate Subscription
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    jwt: state.app.accessToken,
  };
};

export default connect(mapStateToProps)(Confirmation);
