import React from "react";
import "../App.css";

const About = () => {
  React.useEffect(() => {
    // eslint-disable-next-line
  }, []);

  return (
    <div className="flex flex-col flex-1 overflow-x-hidden">
      <main>
        <div className="pt-10 pb-10 md:pt-16 xl:pt-28">
          <div className="px-6 mx-auto">
            <div className="md:items-center md:flex">
              <p className="main-content-label">About</p>
            </div>
          </div>
        </div>

        <div className="px-6 mx-auto">
          <div className="space-y-5 sm:space-y-6">
            <div className="grid gap-5">
              <div className="bg-white border border-gray-200 rounded">
                <div className="px-5 py-4">
                  <p className="text-xs font-medium tracking-wider text-gray-500 uppercase">
                    Here you can learn about VODIUM.
                  </p>
                  <div className="flex items-center justify-between mt-3"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default About;
