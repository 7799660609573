import { showBtnLoading, hideBtnLoading } from "../redux/app/app-action";
import store from "../redux/store";
import { sidebarResponsiveWidth } from "../shared/common/constant";

const appInit = async () => {
  return new Promise((resolve, reject) => {
    // TODO: check user state
    resolve(true);
  });
};

const toggleMainContent = () => {
  const sidebar = document.getElementById("main-content");
  if (sidebar.classList.contains("main-content-maximized")) {
    // sidebar.classList.add("main-content-minimized");
    sidebar.classList.remove("main-content-maximized");
  } else {
    sidebar.classList.add("main-content-maximized");
    sidebar.classList.remove("main-content-minimized");
  }
};

const toggleBtnLoadingOn = () => {
  store.dispatch(showBtnLoading());
};

const toggleBtnLoadingOff = () => {
  store.dispatch(hideBtnLoading());
};

const toggleSidebar = () => {
  const sidebar = document.getElementById("mySidebar");
  const mini = document.getElementById("main-content");
  const footer = document.getElementById("footer");

  if (sidebar.offsetWidth % sidebarResponsiveWidth === 0) {
    if (
      sidebar.classList.contains("minimized") &&
      sidebar.classList.contains("-left-60")
    ) {
      sidebar.classList.add("left-0");
      sidebar.classList.remove("-left-60");
      mini.classList.add("main-content-maximized");
      footer.classList.add("pl-8");
    } else if (
      sidebar.classList.contains("minimized") &&
      sidebar.classList.contains("left-0")
    ) {
      sidebar.classList.add("-left-60");
      sidebar.classList.remove("left-0");
      mini.classList.remove("main-content-maximized");
      footer.classList.remove("pl-8");
    } else if (sidebar.classList.contains("-left-60")) {
      sidebar.classList.add("left-0");
      sidebar.classList.remove("-left-60");
      mini.classList.remove("main-content-maximized");
      footer.classList.add("pl-8");
    } else {
      sidebar.classList.add("-left-60");
      sidebar.classList.remove("left-0");
      footer.classList.remove("pl-8");
    }
  }
};

const toggleMenuContent = () => {
  const menubar = document.getElementById("menubar");
  if (menubar.classList.contains("col-span-2")) {
    menubar.classList.add("col-span-1");
    menubar.classList.remove("col-span-2");
  } else {
    menubar.classList.add("col-span-2");
    menubar.classList.remove("col-span-1");
  }
};

const toggleMenuTextContent = () => {
  const helpContent = document.getElementById("help-content");
  if (helpContent.classList.contains("col-span-4")) {
    helpContent.classList.add("col-span-5");
    helpContent.classList.remove("col-span-4");
  } else {
    helpContent.classList.add("col-span-4");
    helpContent.classList.remove("col-span-5");
  }
};

export {
  appInit,
  toggleMainContent,
  toggleBtnLoadingOn,
  toggleBtnLoadingOff,
  toggleSidebar,
  toggleMenuContent,
  toggleMenuTextContent,
};
