import LicenseManagement from "./LicenseManagement";
import { loginRequest } from "../services/authProvider";
import LicenseManagementUser from "./LicenseManagementUser";
import { useEffect, useState } from "react";
import {
  InteractionRequiredAuthError,
  InteractionStatus,
} from "@azure/msal-browser";
import { callMsGraph, getCurrentUserRole } from "../services/msGraphApiCall";
import { UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { AppDefaults, CurrentRole } from "../shared/common/constant";

const LicenseManagementControl = () => {
  const { instance, inProgress } = useMsal();
  const [pageRedirect, setPageRedirect] = useState();
  const [role, setRole] = useState();
  const [graphData, setGraphData] = useState(null);

  /*currentRole - checks the current logged in user's role to redirect
    to the proper page
    response - contains the default response of the graph users request */
  const currentRole = async (response) => {
    const role = response.value;
    for (let x = 0; x < role.length; x++) {
      if (
        role[x].resourceDisplayName === AppDefaults.ResourceDisplayName &&
        role[x].appRoleId === CurrentRole.ADMIN
      ) {
        console.log("redirect admin");
        setPageRedirect(true);
      } else if (
        role[x].resourceDisplayName === AppDefaults.ResourceDisplayName &&
        role[x].appRoleId === CurrentRole.USER
      ) {
        console.log("redirect user");
        setPageRedirect(false);
      }
    }
  };

  useEffect(() => {
    if (!graphData && inProgress === InteractionStatus.None) {
      callMsGraph()
        .then((response) => setGraphData(response))
        .catch((e) => {
          if (e instanceof InteractionRequiredAuthError) {
            instance.acquireTokenRedirect({
              ...loginRequest,
              account: instance.getActiveAccount(),
            });
          }
        });
    }
    if (!role && inProgress === InteractionStatus.None) {
      getCurrentUserRole()
        .then((response) => {
          setRole(response);
          currentRole(response);
        })
        .catch((e) => {
          if (e instanceof InteractionRequiredAuthError) {
            instance.acquireTokenRedirect({
              ...loginRequest,
              account: instance.getActiveAccount(),
            });
          }
        });
    }
  }, [inProgress, graphData, instance, role]);

  if (!graphData) {
    return (
      <UnauthenticatedTemplate>
        <div className="pt-10 pb-10 md:pt-16 xl:pt-28">
          <div className="px-6 mx-auto">
            <div className="md:items-center md:flex"></div>
          </div>
        </div>
        <div className="px-6 mx-auto h-screen">
          <div className="space-y-5 sm:space-y-6">
            <div className="grid gap-5">
              <div className="bg-white border border-gray-200 rounded">
                <div className="px-5 py-4 center">
                  <svg
                    width="80"
                    height="80"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      {" "}
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M3.46447 3.46447C2 4.92893 2 7.28595 2 12C2 16.714 2 19.0711 3.46447 20.5355C4.92893 22 7.28595 22 12 22C16.714 22 19.0711 22 20.5355 20.5355C22 19.0711 22 16.714 22 12C22 7.28595 22 4.92893 20.5355 3.46447C19.0711 2 16.714 2 12 2C7.28595 2 4.92893 2 3.46447 3.46447ZM8.39747 17.4466C8.64413 17.7794 9.11385 17.8492 9.44661 17.6025C10.175 17.0627 11.0541 16.75 12 16.75C12.9459 16.75 13.825 17.0627 14.5534 17.6025C14.8862 17.8492 15.3559 17.7794 15.6025 17.4466C15.8492 17.1138 15.7794 16.6441 15.4466 16.3975C14.4742 15.6767 13.285 15.25 12 15.25C10.715 15.25 9.5258 15.6767 8.55339 16.3975C8.22062 16.6441 8.15082 17.1138 8.39747 17.4466ZM16 10.5C16 11.3284 15.5523 12 15 12C14.4477 12 14 11.3284 14 10.5C14 9.67157 14.4477 9 15 9C15.5523 9 16 9.67157 16 10.5ZM9 12C9.55228 12 10 11.3284 10 10.5C10 9.67157 9.55228 9 9 9C8.44772 9 8 9.67157 8 10.5C8 11.3284 8.44772 12 9 12Z"
                        fill="#1C274C"
                      ></path>{" "}
                    </g>
                  </svg>
                  <p>Sorry!</p>
                  <p>
                    You do not have access to this Page. Please sign in first to
                    use this feature.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </UnauthenticatedTemplate>
    );
  } else if (!pageRedirect) {
    return <LicenseManagementUser />;
  } else {
    return <LicenseManagement />;
  }
};

//   if(role.resourceDisplayName === 'vodium-landing-page-dev' && role.appRoleId === "4bb65472-f79a-45c2-9f16-be3219a32988") {
//     console.log('redirect admin')
//     return <LicenseManagement/>;
//   }
//   else if(role.resourceDisplayName === 'vodium-landing-page-dev' && role.appRoleId === "10d1d322-4e8a-4990-88b5-a5bc5b8e6ea3") {
//     console.log('redirect user')
//     return <LicenseManagementUser/>
//   }

// useEffect(()=> {
//     // const accessRole = async () => {
//     //     msalInstance.clearCache()
//     //     const token = await msalInstance.acquireTokenSilent({
//     //         ...loginRequest,
//     //         account: msalInstance.getActiveAccount()
//     //       }).catch((e) => {
//     //         if (e instanceof InteractionRequiredAuthError) {
//     //           msalInstance.acquireTokenRedirect({
//     //             ...loginRequest,
//     //             account: msalInstance.getActiveAccount(),
//     //           });
//     //         }
//     //       });
//     //       console.log(token)
//     //       const currentRole = token.idTokenClaims.roles;;
//     //       setRole(JSON.stringify(currentRole))
//     //       console.log('testing',currentRole)
//     // }
//     // accessRole()
// },[])

// if(role && role.includes('LandingPage.Admin')) {
//     console.log('test test')
//         return <LicenseManagement />
//     }
//     else if(role){
//         console.log('else return')
//        return <LicenseManagement/>
//     }

//}
export default LicenseManagementControl;
