import { loginRequest, graphConfig } from './authProvider';
import { msalInstance } from '..';
import { AppDefaults, CurrentRole, GRAPH_API } from '../shared/common/constant';

export async function callMsGraph(accessToken) {
  if (!accessToken) {
    const account = msalInstance.getActiveAccount();
    if (!account) {
      throw Error(
        'No active account! Verify a user has been signed in and setActiveAccount has been called.'
      );
    }

    const response = await msalInstance.acquireTokenSilent({
      ...loginRequest,
      account: account,
    });
    accessToken = response.accessToken;
  }

  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append('Authorization', bearer);

  const options = {
    method: 'GET',
    headers: headers,
  };

  return fetch(graphConfig.graphMeEndpoint, options)
    .then((response) => response.json())
    .catch((error) => console.log(error));
}
export async function getAccessToken() {
  const account = msalInstance.getActiveAccount();
  if (!account) {
    throw Error(
      'No active account! Verify a user has been signed in and setActiveAccount has been called.'
    );
  }
  const response = await msalInstance.acquireTokenSilent({
    ...loginRequest,
    account: account,
  });
  const accessToken = response.accessToken;

  return `Bearer ${accessToken}`;
}
export async function callUsers(accessToken, searchValue) {
  if (!accessToken) {
    const account = msalInstance.getActiveAccount();
    if (!account) {
      throw Error(
        'No active account! Verify a user has been signed in and setActiveAccount has been called.'
      );
    }

    const response = await msalInstance.acquireTokenSilent({
      ...loginRequest,
      account: account,
    });
    accessToken = response.accessToken;
  }

  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append('Authorization', bearer);

  const options = {
    method: 'GET',
    headers: headers,
  };

  var modifiedURL = graphConfig.graphPeopleEndpoint + '';
  if (searchValue && searchValue.trim() !== '') {
    modifiedURL += "?$filter=startswith(displayName,'" + searchValue + "') ";
    modifiedURL += "or startswith(givenName,'" + searchValue + "') ";
    modifiedURL += "or startswith(surname,'" + searchValue + "') ";
    modifiedURL += "or startswith(mail,'" + searchValue + "') ";
    modifiedURL += "or startswith(userPrincipalName,'" + searchValue + "')";
  }

  return fetch(modifiedURL, options)
    .then((response) => response.json())
    .catch((error) => console.log(error));
}

export async function getUserAppRoles(accessToken, userId) {
  if (!accessToken) {
    const account = msalInstance.getActiveAccount();
    if (!account) {
      throw Error(
        'No active account! Verify a user has been signed in and setActiveAccount has been called.'
      );
    }

    const response = await msalInstance.acquireTokenSilent({
      ...loginRequest,
      account: account,
    });
    accessToken = response.accessToken;
  }

  const options = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Consent-Type': 'application/json',
    },
  };

  return fetch(GRAPH_API + `/users/${userId}/appRoleAssignments`, options)
    .then((response) => response.json())
    .catch((error) => console.log(error));
}

export async function addAppRoleAdmin(accessToken, principalId) {
  if (!accessToken) {
    const account = msalInstance.getActiveAccount();
    if (!account) {
      throw Error(
        'No active account! Verify a user has been signed in and setActiveAccount has been called.'
      );
    }

    const response = await msalInstance.acquireTokenSilent({
      ...loginRequest,
      account: account,
    });
    accessToken = response.accessToken;
  }
  const responseAdmin = await fetch(
    GRAPH_API + `/users/${principalId}/appRoleAssignments`,
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        principalId: `${principalId}`,
        resourceId: AppDefaults.ResourceId,
        appRoleId: CurrentRole.ADMIN,
      }),
    }
  );

  if (responseAdmin.ok) {
    console.log('Role assigned successfully');
  } else {
    console.error('Error assigning role:', await responseAdmin.json());
  }
  return null;
}

export async function addAppRoleUser(accessToken, principalId) {
  if (!accessToken) {
    const account = msalInstance.getActiveAccount();
    if (!account) {
      throw Error(
        'No active account! Verify a user has been signed in and setActiveAccount has been called.'
      );
    }

    const response = await msalInstance.acquireTokenSilent({
      ...loginRequest,
      account: account,
    });
    accessToken = response.accessToken;
  }

  const responseUser = await fetch(
    GRAPH_API + `/users/${principalId}/appRoleAssignments`,
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        principalId: `${principalId}`,
        resourceId: AppDefaults.ResourceId,
        appRoleId: CurrentRole.USER,
      }),
    }
  );

  if (responseUser.ok) {
    console.log('Role assigned successfully');
  } else {
    console.error('Error assigning role:', await responseUser.json());
  }
  return null;
}

export async function removeAppRole(accessToken, appRoleId) {
  if (!accessToken) {
    const account = msalInstance.getActiveAccount();
    if (!account) {
      throw Error(
        'No active account! Verify a user has been signed in and setActiveAccount has been called.'
      );
    }

    const response = await msalInstance.acquireTokenSilent({
      ...loginRequest,
      account: account,
    });
    accessToken = response.accessToken;
  }

  const responseDelete = await fetch(
    GRAPH_API +
      `/servicePrincipals/e5f98055-07f4-4fa0-a1f4-a8e97bba74d3/appRoleAssignedTo/${appRoleId}`,
    {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );

  if (responseDelete.ok) {
    console.log('Role assignment deleted');
  } else {
    console.error('Error deleting roles: ', await responseDelete.json());
  }
}

export async function getCurrentUserRole(accessToken) {
  if (!accessToken) {
    const account = msalInstance.getActiveAccount();
    if (!account) {
      throw Error(
        'No active account! Verify a user has been signed in and setActiveAccount has been called.'
      );
    }

    const response = await msalInstance.acquireTokenSilent({
      ...loginRequest,
      account: account,
    });
    accessToken = response.accessToken;
  }

  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append('Authorization', bearer);

  const options = {
    method: 'GET',
    headers: headers,
  };

  return fetch(GRAPH_API + `/me/appRoleAssignments`, options)
    .then((response) => response.json())
    .catch((error) => console.log(error));
}

export async function assignLicense(accessToken, principalId, skuId) {
  if (!accessToken) {
    const account = msalInstance.getActiveAccount();
    if (!account) {
      throw Error(
        'No active account! Verify a user has been signed in and setActiveAccount has been called.'
      );
    }

    const response = await msalInstance.acquireTokenSilent({
      ...loginRequest,
      account: account,
    });
    accessToken = response.accessToken;
  }

  const response = await fetch(
    GRAPH_API + `/users/${principalId}/assignLicense`,
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        addLicenses: [
          {
            disabledPlans: [],
            skuId: `${skuId}`,
          },
        ],
        removeLicenses: [],
      }),
    }
  );

  if (response.ok) {
    console.log('License assigned successfully');
  } else {
    console.error('Error assigning license:', await response.json());
  }
  return null;
}

export async function removeLicense(accessToken, principalId, guId) {
  if (!accessToken) {
    const account = msalInstance.getActiveAccount();
    if (!account) {
      throw Error(
        'No active account! Verify a user has been signed in and setActiveAccount has been called.'
      );
    }

    const response = await msalInstance.acquireTokenSilent({
      ...loginRequest,
      account: account,
    });
    accessToken = response.accessToken;
  }

  const response = await fetch(
    GRAPH_API + `/users/${principalId}/assignLicense`,
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        removeLicenses: [`${guId}`],
      }),
    }
  );

  if (response.ok) {
    console.log('License removed successfully');
  } else {
    console.error('Error removing license:', await response.json());
  }
  return null;
}
