import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { classNames } from "./Utils";

export function Button({ children, className, ...rest }) {
  return (
    <button
      type="button"
      className={classNames(className)}
      {...rest}
    >
      {children}
    </button>
  );
}

export function PageButtonMobile({ children, className, ...rest }) {
  return (
    <button
      type="button"
      className={classNames(
        "relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50",
        className
      )}
      {...rest}
    >
      {children}
    </button>
  );
}

export function PageButton({ children, className, ...rest }) {
  return (
    <button
      type="button"
      className={classNames(
        "relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50",
        className
      )}
      {...rest}
    >
      {children}
    </button>
  );
}

export function MenuButton({ children, className, menuItems, selectOption, ...rest }) {

  function getMenu() {
    return menuItems ?
    (
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-50 focus:outline-none">
          <div className="py-1">
            {
              menuItems.map(
                (item, index) => 
                <Menu.Item
                  onClick={() => selectOption(item)}
                  key={index}
                >
                  {({ active }) => (
                    <button
                      className={classNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'block flex w-full px-4 py-2 text-sm'
                      )}
                    >
                      {item.value}
                    </button>
                  )}
                </Menu.Item>
              )
            }
          </div>
        </Menu.Items>
      </Transition>
    ) : null;
  }

  return (
    <>
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button 
            type="button"
            className={classNames(className)}
            {...rest}
          >
            {children}
          </Menu.Button>
        </div>
        {getMenu()}
      </Menu>
    </>
  );
}
