import React, { Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import Sidebar from "./components/Sidebar";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Vodium from "./pages/Vodium";
import Home from "./pages/Home";
import About from "./pages/About";
import Help from "./pages/Help";
import Contact from "./pages/Contact";
import Confirmation from "./pages/Confirmation";
import PageNotFound from "./pages/404_page";
import ProtectedRoute from "./services/guards/protected-route";
import { connect } from "react-redux";
import { Failure, Success } from "./shared/Alert";
import { ActionTypes } from "./shared/common/constant";
import { appInit } from "./services/app.service";
import "./App.css";
import { MsalProvider } from "@azure/msal-react";
import LicenseManagementControl from "./pages/LicenseManagementControl";
import Subscriptions from "./pages/Subscriptions";
import Activate from "./pages/Activate";
import Navbar from "./components/Navbar";

function App({ alerts, pca }) {
  const [isAppInit, setIsAppInit] = React.useState(false);

  React.useEffect(() => {
    appInit().then((appInit) => {
      setIsAppInit(true);
    });
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    // TODO: check if user is authenticated else navigate to login
    // eslint-disable-next-line
  }, []);

  return (
    <Suspense>
      <MsalProvider instance={pca}>
        <div className="bg-gray-50 min-h-screen">
          <div className="flex flex-col absolute pt-14 top-8 right-6 z-50">
            {alerts.map((al, alertIndex) => {
              return (
                <React.Fragment key={alertIndex}>
                  {al.type === ActionTypes.successAlert && (
                    <Success index={alertIndex}>{al.text}</Success>
                  )}
                  {al.type === ActionTypes.failAlert && (
                    <Failure index={alertIndex}>{al.text}</Failure>
                  )}
                </React.Fragment>
              );
            })}
          </div>
          <Routes>
            {/* Home */}
            <Route
              path="/*"
              element={
                <ProtectedRoute isLoggedIn={true} isAppInit={isAppInit}>
                  <div>
                    <Header />
                    <Navbar />
                    <div>
                      <Sidebar />
                      <div
                        id="main-content"
                        className="flex flex-col flex-1 overflow-x-hidden xl:pl-64"
                      >
                        <main>
                          <div>
                            <Routes>
                              <Route path="vodium" element={<Vodium />} />
                              <Route path="home" element={<Home />} />
                              <Route
                                path="licenses"
                                element={<LicenseManagementControl />}
                              />
                              <Route path="about" element={<About />} />
                              <Route path="help" element={<Help />} />
                              <Route path="contact" element={<Contact />} />
                              <Route
                                path="subscriptions"
                                element={<Subscriptions />}
                              />
                              {/* 404 Page */}
                              <Route path="*" element={<PageNotFound />} />
                            </Routes>
                          </div>
                        </main>
                      </div>
                    </div>
                    <Footer />
                  </div>
                </ProtectedRoute>
              }
            />

            {/* Confirmation */}
            <Route
              path="confirmation"
              element={
                <div>
                  <div className="flex flex-col flex-1 overflow-x-hidden">
                    <Confirmation />
                  </div>
                  <Footer />
                </div>
              }
            />

            {/* Activate */}
            <Route
              path="activate"
              element={
                <div>
                  {/* <Header /> */}
                  <div className="flex flex-col flex-1 overflow-x-hidden">
                    <Activate />
                  </div>
                  <Footer />
                </div>
              }
            />
          </Routes>
        </div>
      </MsalProvider>
    </Suspense>
  );
}

const mapStateToProps = (state) => {
  return {
    alerts: state.app.alerts,
  };
};

export default connect(mapStateToProps)(App);
