/** This is the Help page where the customer can
 * access Vodium support materials with helpful
 * links, tips, and FAQs
 * This page is also referenced in the Teams App
 * as the Publisher Docs
 * ENDPOINT -> /support */

import React from "react";
import "./css/Help.css";
import "../App.css";
import faqList from "../files/faq.json";
import { URL_GUIDE } from "../shared/common/constant";
import {
  toggleMenuContent,
  toggleMenuTextContent,
} from "../services/app.service";
import HelpMobile from "./HelpMobile";

const Help = () => {
  const [isMinimized, setIsMinimized] = React.useState(false);
  const [isMenuMinimized, setIsMenuMinimized] = React.useState(false);
  const [collapsedFaq, setCollapseFaq] = React.useState(
    Array(faqList.length).fill(false)
  );
  const [view, setView] = React.useState({
    id: "ABOUT_THE_APP",
    display: "About",
  });
  const toggleCollapse = (index) => {
    const newCollapsedStates = [...collapsedFaq];
    newCollapsedStates[index] = !newCollapsedStates[index];
    setCollapseFaq(newCollapsedStates);
  };
  const toggleView = (value) => {
    setView(value);
  };
  function toggleMenubar() {
    toggleMenuContent();
    toggleMenuTextContent();
    setIsMinimized(!isMinimized);
    setIsMenuMinimized(!isMenuMinimized);
  }

  React.useEffect(() => {
    // eslint-disable-next-line
  }, []);

  return (
    <div className="flex flex-col flex-1 overflow-x-hidden help-page">
      <main>
        <div className="hidden md:flex">
          <div className="pt-20 pb-10 md:pt-28">
            <div className="px-6 mx-auto">
              <div className="md:items-center md:flex">
                <p className="main-content-label">
                  VODIUM Support{" "}
                  <span className="view-label">/ {view.display}</span>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="hidden md:grid px-6 mx-auto search-div">
          <div className="space-y-7 sm:space-y-6">
            <div className="grid gap-5">
              <div className="bg-white border border-gray-200 rounded">
                <div className="px-5 py-4">
                  <div className="items-center justify-between mt-3">
                    <p className="none-gradient">
                      {" "}
                      Welcome to <span className="gradient-text">
                        VODIUM
                      </span>{" "}
                      Support
                    </p>
                    <p>
                      Frequently asked questions and helpful resources to get
                      the most out of VODIUM
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="hidden md:grid grid-cols-1 md:grid-cols-6">
          <div id="menubar" className={`col-span-2`}>
            <div className="grid flex-col flex-initial overflow-x-hidden">
              <div className="py-2 mt-2 pb-24">
                <div className="items-start">
                  <div className="px-6 mx-auto">
                    <div className="space-y-5 sm:space-y-6">
                      <div className="grid gap-5">
                        <div
                          className="bg-white border border-gray-200 rounded support-options-div"
                          onClick={() =>
                            toggleView({
                              id: "ABOUT_THE_APP",
                              display: "About",
                            })
                          }
                        >
                          <div className="px-5 py-4">
                            <div className="items-center justify-between">
                              <p className="text-base font-extrabold lg:text-lg">
                                About
                              </p>
                            </div>
                            <div
                              className={`transition-all duration-500 ${
                                isMenuMinimized && `hidden`
                              }`}
                            >
                              <p className="text-xs">
                                Learn more about VODIUM for Microsoft Teams
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="px-6 mx-auto">
                    <div className="space-y-5 sm:space-y-6">
                      <div className="grid gap-5">
                        <div
                          className="bg-white border border-gray-200 rounded support-options-div"
                          onClick={() =>
                            toggleView({
                              id: "INSTRUCTIONS",
                              display: "Adoption Instructions",
                            })
                          }
                        >
                          <div className="px-5 py-4">
                            <div className="items-center justify-between">
                              <p className="text-base font-extrabold lg:text-lg">
                                Adoption Instructions
                              </p>
                            </div>
                            <div className={`${isMenuMinimized && `hidden`}`}>
                              <p className="text-xs">
                                Elevate your experience with VODIUM.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="px-6 mx-auto">
                    <div className="space-y-5 sm:space-y-6">
                      <div className="grid gap-5">
                        <div
                          className="bg-white border border-gray-200 rounded support-options-div"
                          onClick={() =>
                            toggleView({
                              id: "PROPOSED_APP_ROLL_OUT",
                              display: "Proposed Rollout",
                            })
                          }
                        >
                          <div className="px-5 py-4">
                            <div className="items-center justify-between">
                              <p className="text-base font-extrabold lg:text-lg">
                                Proposed Rollout
                              </p>
                            </div>
                            <div className={`${isMenuMinimized && `hidden`}`}>
                              <p className="text-xs">
                                Unlock the full potential of VODIUM!
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="px-6 mx-auto">
                    <div className="space-y-5 sm:space-y-6">
                      <div className="grid gap-5">
                        <div
                          className="bg-white border border-gray-200 rounded support-options-div"
                          onClick={() =>
                            toggleView({
                              id: "INSTRUCTIONS_INFORMATION",
                              display: "Instructions",
                            })
                          }
                        >
                          <div className="px-5 py-4">
                            <div className="items-center justify-between">
                              <p className="text-base font-extrabold lg:text-lg">
                                Instructions
                              </p>
                            </div>
                            <div className={`${isMenuMinimized && `hidden`}`}>
                              <p className="text-xs">
                                Empower your communication journey with VODIUM –
                                where expertise meets innovation!
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="px-6 mx-auto">
                    <div className="space-y-5 sm:space-y-6">
                      <div className="grid gap-5">
                        <div
                          className="bg-white border border-gray-200 rounded support-options-div"
                          onClick={() =>
                            toggleView({
                              id: "USER_GUIDE",
                              display: "User Guide",
                            })
                          }
                        >
                          <div className="px-5 py-4">
                            <div className="items-center justify-between">
                              <p className="text-base font-extrabold lg:text-lg">
                                User Guide
                              </p>
                            </div>
                            <div className={`${isMenuMinimized && `hidden`}`}>
                              <p className="text-xs">
                                Some helpful tips to get you started using
                                VODIUM.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="px-6 mx-auto">
                    <div className="space-y-5 sm:space-y-6">
                      <div className="grid gap-5">
                        <div
                          className="bg-white border border-gray-200 rounded support-options-div"
                          onClick={() => {
                            toggleView({ id: "FAQ", display: "FAQs" });
                          }}
                        >
                          <div className="px-5 py-4">
                            <div className="items-center justify-between">
                              <p className="text-base font-extrabold lg:text-lg">
                                FAQs
                              </p>
                            </div>
                            <div className={`${isMenuMinimized && `hidden`}`}>
                              <p className="text-xs">
                                Find quick answers to our frequently asked
                                questions.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="px-6 mx-auto">
                  <div className="space-y-5 sm:space-y-6">
                    <div className="flex items-center justify-center md:justify-end">
                      <button
                        className="icon-button-collapse svg-container rotate-90 md:rotate-0"
                        onClick={toggleMenubar}
                        title={isMinimized ? "Open" : "Close"}
                      >
                        {isMinimized && (
                          <svg
                            className="side-bar-icon"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g id="SVGRepo_bgCarrier" strokeWidth="0" />
                            <g
                              id="SVGRepo_tracerCarrier"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <g id="SVGRepo_iconCarrier">
                              <path
                                d="M5.5 5L11.7929 11.2929C12.1834 11.6834 12.1834 12.3166 11.7929 12.7071L5.5 19"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M13.5 5L19.7929 11.2929C20.1834 11.6834 20.1834 12.3166 19.7929 12.7071L13.5 19"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </g>
                          </svg>
                        )}

                        {!isMinimized && (
                          <svg
                            className="side-bar-icon"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g id="SVGRepo_bgCarrier" strokeWidth="0" />
                            <g
                              id="SVGRepo_tracerCarrier"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <g id="SVGRepo_iconCarrier">
                              <path
                                d="M19 19L12.7071 12.7071C12.3166 12.3166 12.3166 11.6834 12.7071 11.2929L19 5"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M11 19L4.70711 12.7071C4.31658 12.3166 4.31658 11.6834 4.70711 11.2929L11 5"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </g>
                          </svg>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* The following code shows the content side of the page */}
          <div id="help-content" className="col-span-4">
            <div className="flex flex-col flex-1">
              <div className="py-2 mt-2 pb-18">
                <div className="items-start">
                  <div className="px-6 mx-auto">
                    <div className="space-y-8 sm:space-y-6">
                      <div className="grid gap-5">
                        <div className="items-center justify-between">
                          {view.id === "FAQ" && (
                            <div className="support-content column">
                              {faqList.map((item, index) => (
                                <div
                                  key={item.id}
                                  className={` faq-div ${
                                    !collapsedFaq[index]
                                      ? "faq-uncollapsed"
                                      : "faq-collapsed"
                                  }`}
                                >
                                  <div
                                    onClick={() => toggleCollapse(index)}
                                    className="faq-question-div"
                                  >
                                    <span className="faq-question">
                                      {" "}
                                      {item.question}{" "}
                                    </span>
                                    {!collapsedFaq[index] ? (
                                      <span className="faq-question-icon">
                                        +
                                      </span>
                                    ) : (
                                      <span className="faq-question-icon">
                                        <hr className="long-dash" />
                                      </span>
                                    )}
                                  </div>
                                  {!collapsedFaq[index] ? null : (
                                    <div className="faq-answer">
                                      {item.answer}
                                      <br />
                                    </div>
                                  )}
                                </div>
                              ))}
                            </div>
                          )}
                          {view.id === "USER_GUIDE" && (
                            <iframe title="User Guide" src={URL_GUIDE}/>
                          )}
                          {view.id === "ABOUT_THE_APP" && (
                            <div className="bg-white border border-gray-200 rounded">
                              <div className="px-5 py-2 pb-4">
                                <div className="items-center justify-between mt-3">
                                  <div className="support-content column">
                                    <p>
                                      VODIUM is an easy-to-use teleprompter
                                      built to assist and support professionals
                                      by allowing them to read off their notes
                                      or script while also giving the appearance
                                      of looking directly at their meeting
                                      attendees.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                          {view.id === "INSTRUCTIONS" && (
                            <div className="bg-white border border-gray-200 rounded">
                              <div className="px-5 py-2 pb-4">
                                <div className="items-center justify-between mt-3">
                                  <div className="support-content column">
                                    <p>
                                      App adoption not only needs to involve
                                      understanding how to technically control
                                      VODIUM, but also understanding the desired
                                      results. VODIUM is built to help
                                      professionals who are in the distributed
                                      workspace who conduct important
                                      conversations utilizing their computers.
                                      Individuals and teams should spend time
                                      practicing and prepping with the app by{" "}
                                      <a
                                        href="https://www.youtube.com/channel/UCF0Nbhv83aBo8Iw-B-Rmkig"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{
                                          color: "blue",
                                          textDecoration: "underline",
                                        }}
                                      >
                                        helpful videos and tips
                                      </a>
                                      .
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                          {view.id === "PROPOSED_APP_ROLL_OUT" && (
                            <div className="bg-white border border-gray-200 rounded">
                              <div className="px-5 py-2 pb-4">
                                <div className="items-center justify-between mt-3">
                                  <div className="support-content column">
                                    <p>
                                      A majority of our individual customers use
                                      VODIUM for presentations, pre-recordings,
                                      and or interviews. Businesses use the app
                                      to plan multi-person presentations on
                                      video, or webcast teams who virtually
                                      support hundreds of internal and external
                                      speakers. We at VODIUM propose a time to
                                      onboard each user on the app. Then allow
                                      for plenty of practice and prep sessions
                                      to feel confident on how to control the
                                      app while focusing on delivery.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                          {view.id === "INSTRUCTIONS_INFORMATION" && (
                            <div className="bg-white border border-gray-200 rounded">
                              <div className="px-5 py-2 pb-4">
                                <div className="items-center justify-between mt-3">
                                  <div className="support-content column">
                                    <p>
                                      We at VODIUM are a team of trained experts
                                      in the video communication space. We
                                      recommend that admins assist users on how
                                      to add the app into the Microsoft Teams
                                      experience. We also recommend that admins
                                      encourage practice and prep on how to
                                      utilize the app during a presentation,
                                      pre-recorded video, meeting, etc. Users
                                      can access VODIUM in the Tabs experience,
                                      and pop-out the app to position it near
                                      the computer’s camera. Users can also
                                      utilize the teleprompter during the
                                      In-Meeting experience, and can position
                                      the app towards the middle of the computer
                                      screen to look like you’re looking at the
                                      attendees.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

      {/* This view is visible for devices with a small resolution minimum width of 640px only */}
      <div className="grid md:hidden">
        <HelpMobile />
      </div>
    </div>
  );
};

export default Help;
