import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import store from "./redux/store";
import { BrowserRouter } from "react-router-dom";

// MSAL imports
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { msalConfig } from "./services/authProvider";
import { setAccessToken } from "./redux/app/app-action";
import { getAccessToken } from "./services/msGraphApiCall";

export const msalInstance = new PublicClientApplication(msalConfig);

// Initialize MSAL and fetch access token
const initializeMsal = async () => {
  try {
    await msalInstance.initialize();
    // Default to using the first account if no account is active on page load
    if (msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
      msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
    }

    // Optional - Update account state if a user signs in from another tab/window
    msalInstance.enableAccountStorageEvents();

    msalInstance.addEventCallback((event) => {
      if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
        const account = event.payload.account;
        msalInstance.setActiveAccount(account);
      }
    });

    // Fetch access token
    const accessToken = await getAccessToken();
    store.dispatch(setAccessToken(accessToken));
  } catch (error) {
    console.error('Error initializing MSAL or fetching token:', error);
  }
};

// Initialize MSAL and render app
initializeMsal().then(() => {
  const root = ReactDOM.createRoot(document.getElementById("root"));
  root.render(
    <Provider store={store}>
      <BrowserRouter>
        <App pca={msalInstance} />
      </BrowserRouter>
    </Provider>
  );
});
