import React, { useState } from "react";
import "../../App.css";
import { ACTION_HANDLER_TYPE } from "../../shared/common/constant";
import ActionHandler from "../../services/data-handler";

const ReportBugForm = (props) => {
  const [inputValues, setInputValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    category: "",
    message: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (["email", "category", "message"].includes(name)) {
      if (value.trim() === "") {
        displayError(
          name + "Error",
          name.charAt(0).toUpperCase() + name.slice(1) + " cannot be empty."
        );
      } else {
        if (name === "email" && !isValidEmail(value.trim())) {
          displayError("emailError", "Invalid email format.");
        } else {
          clearError(name);
        }
      }
    }
    setInputValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const isValidEmail = (email) => {
    // Regular expression for basic email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const clearError = (elementId) => {
    document.getElementById(elementId).style.border = "1px solid #6b7280";
    document.getElementById(elementId + "Error").style.display = "none";
  };

  const displayError = (elementId, message) => {
    document.getElementById(elementId).innerText = message;
    document.getElementById(elementId).style.display = "block";
    document.getElementById(elementId).previousSibling.style.border =
      "1px solid #dd59c3";
  };

  const validateForm = () => {
    const email = document.getElementById("email").value;
    const category = document.getElementById("category").value;
    const message = document.getElementById("message").value;

    var isValid = true;
    if (email.trim() === "") {
      displayError("emailError", "Email cannot be empty.");
      isValid = false;
    } else {
      if (!isValidEmail(email.trim())) {
        displayError("emailError", "Invalid email format.");
        isValid = false;
      }
    }
    if (category.trim() === "") {
      displayError("categoryError", "Please choose a category.");
      isValid = false;
    }
    if (message.trim() === "") {
      displayError("messageError", "Message cannot be empty.");
      isValid = false;
    }
    return isValid;
  };

  const submit = () => {
    if (validateForm()) {
      document.getElementById("loaderOverlay").style.display = "flex";
      ActionHandler(ACTION_HANDLER_TYPE.SEND_EMAIL_BUG, inputValues).then(
        () => {
          document.getElementById("loaderOverlay").style.display = "none";
          props.toggleView({ id: "EMAIL_SENT", display: "" });
        }
      );
    }
  };

  React.useEffect(() => {
    // eslint-disable-next-line
  }, []);
  return (
    <div className="loader-container">
      <div
        className="grid grid-cols-1 md:grid-cols-2 loader-overlay"
        id="loaderOverlay"
      >
        <div className="loader"></div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2">
        <div className="form-div column">
          <label
            htmlFor="email"
            className="text-xs font-medium tracking-wider text-gray-500 uppercase"
          >
            Email *
          </label>
          <input
            className="form-input"
            type="text"
            id="email"
            name="email"
            placeholder="Your email.."
            value={inputValues.email}
            onChange={handleInputChange}
          />
          <div id="emailError" className="error-message"></div>
        </div>
        <div className="form-div column">
          <label
            htmlFor="phoneNumber"
            className="text-xs font-medium tracking-wider text-gray-500 uppercase"
          >
            Phone Number
          </label>
          <input
            className="form-input"
            type="text"
            id="phoneNumber"
            name="phoneNumber"
            placeholder="Your phone number.."
            value={inputValues.phoneNumber}
            onChange={handleInputChange}
          />
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2">
        <div className="form-div column">
          <label
            htmlFor="firstName"
            className="text-xs font-medium tracking-wider text-gray-500 uppercase"
          >
            First Name
          </label>
          <input
            className="form-input"
            type="text"
            id="firstName"
            name="firstName"
            placeholder="Your first name.."
            value={inputValues.firstName}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-div column">
          <label
            htmlFor="lastName"
            className="text-xs font-medium tracking-wider text-gray-500 uppercase"
          >
            Last Name
          </label>
          <input
            className="form-input"
            type="text"
            id="lastName"
            name="lastName"
            placeholder="Your last name.."
            value={inputValues.lastName}
            onChange={handleInputChange}
          />
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2">
        <div className="form-div column">
          <label
            htmlFor="category"
            className="text-xs font-medium tracking-wider text-gray-500 uppercase"
          >
            Category *
          </label>
          <select
            className="form-input"
            id="category"
            name="category"
            value={inputValues.category}
            onChange={handleInputChange}
          >
            <option value="" disabled={true}>
              Please Select
            </option>
            <option value="Teams App Tab">Teams App Tab</option>
            <option value="Teams App In-meeting">Teams App In-meeting</option>
            <option value="App Functionality">App Functionality</option>
            <option value="Subscription">Subscription</option>
            <option value="Others">Others</option>
          </select>
          <div id="categoryError" className="error-message"></div>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2">
        <div className="col-span-2">
          <div className="form-div column textarea-div">
            <label
              htmlFor="message"
              className="text-xs font-medium tracking-wider text-gray-500 uppercase"
            >
              Message *
            </label>
            <textarea
              className="form-input"
              id="message"
              name="message"
              placeholder="Your message.."
              value={inputValues.message}
              onChange={handleInputChange}
            />
            <div id="messageError" className="error-message"></div>
          </div>
        </div>
      </div>
      <button
        className="blue button submit-button"
        type="submit"
        onClick={submit}
      >
        Submit
      </button>
    </div>
  );
};
export default ReportBugForm;
